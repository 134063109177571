/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SpinnerDotted } from "spinners-react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { Entry } from "../../redux/entrySlice";
import styles from "./editUser.module.scss";
import { TableContext } from "../../contexts/tableContext";
import "react-toastify/dist/ReactToastify.css";
import PasswordField from "../../components/EditPasswordField/PasswordField";

const UserEdit = React.memo(() => {
  let userId;
  const [submiting, setSubmitting] = useState(false);
  const { dashboardStyle } = useContext(TableContext);
  // const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const params = useParams();
  const [user, setUser] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const {
    // control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader("Change Password");
    userId = params.userId;
    const actionData = {};
    actionData.actionUrl = `user/${userId}`;
    actionData.actionMethod = "get";
    dispatch(Entry(actionData)).then((resp) => {
      // delete resp.payload.id;
      console.log(resp, "check user");
      setUser(resp.payload.data.user);
      reset(resp.payload.data.user);
    });
  }, []);
  // eslint-disable-next-line no-unused-vars
  function onSubmitdata(data) {
    if (data.name.trim().length === 0) {
      document.getElementById("name").focus();
    } else {
      setSubmitting(true);
      setError(null);
      const apiData = {
        password: data.password,
      };
      data.actionUrl = `user/change-password/${params.userId}`;
      data.actionMethod = "patch";
      data.apiData = apiData;
      dispatch(Entry(data)).then((resp) => {
        setSubmitting(false);
        if (resp.payload.code === 200) {
          navigate(-1);
          toast.success(resp.payload.data);
          // eslint-disable-next-line max-len
        } else if (
          resp.payload.code === 401 ||
          resp.payload.code === 400 ||
          resp.payload.code === 404
        ) {
          setError(resp.payload.message);
          toast.error("Error");
        } else {
          navigate(-1);
        }
      });
    }
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5 edituserform">
            {user.length !== 0 ? (
              <form onSubmit={handleSubmit(onSubmitdata)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <PasswordField
                      fieldName="password"
                      label="New Password"
                      register={register}
                      errors={errors}
                      placeHolder="Enter new password"
                      isRequired
                      minimLength={8}
                      eyeiconstyle={styles.eyeicon}
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                    />
                  </div>
                </div>
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? "Please wait.." : "Update"}
                />
                {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
              </form>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "130px",
                  color: "#39979d",
                }}
              >
                <SpinnerDotted />
              </div>
            )}
            <br />
            <ToastContainer autoClose={2000} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default UserEdit;
