/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */
import axios from "axios";
// import Avatar from 'react-avatar';
import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { toast } from "react-toastify";
// import { confirmAlert } from "react-confirm-alert";
// import { useDispatch } from "react-redux";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import styles from "./view.module.scss";
import envValues from "../../enviornment";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
// import { Entry } from "../../redux/entrySlice";

function ViewContest() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const params = useParams();
  let cmsId;
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const dateFormat = getCookies("dateFormat");
  // const dispatch = useDispatch();
  let statusCode;
  const getContestDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/cms/${cmsId}`, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.cms);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    cmsId = params.cmsId;
    getContestDetails();
    setDashboardHeader("Cms Details");
  }, []);

  // const publishCms = (data) => {
  //   confirmAlert({
  //     title: "",
  //     message: "Are you sure you want to publish this?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => {
  //           const actionData = {};
  //           actionData.actionUrl = `cms/publish`;
  //           actionData.actionMethod = "patch";
  //           actionData.apiData = { cmsId: data.id };
  //           dispatch(Entry(actionData)).then(() => {
  //             toast.success("Published Successfully");
  //             navigate(-1);
  //           });
  //         },
  //       },
  //       {
  //         label: "No",
  //         // onClick: () => alert("Click No")
  //       },
  //     ],
  //   });
  // };
  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          {loading ? (
            <SpinnerDotted
              style={{
                color: "#39979d",
                left: "50%",
                position: "relative",
                textAlign: "center",
                top: "50%",
              }}
            />
          ) : (
            <Container fluid>
              <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              />
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Name</span>
                          <p className={styles.empname}>
                            {details.name === undefined || ""
                              ? "N/A"
                              : details?.name}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Alias</span>
                          <p className={styles.empname}>
                            {details.alias === undefined || ""
                              ? "N/A"
                              : details?.alias}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Status</span>
                          <p className={styles.empname}>
                            {details.status === undefined || ""
                              ? "N/A"
                              : capitalizeFirstLetter(details.status)}
                          </p>
                        </div>
                      </Col>
                      {details?.status === "published" && (
                        <Col lg={6}>
                          <div
                            className={styles[viewformStyle]}
                            id={styles.empdiv}
                          >
                            <span className={styles.title}>Published Date</span>
                            <p className={styles.empname}>
                              {details.publishedDate
                                ? DateFormatter(
                                    details.publishedDate,
                                    dateFormat || "toDateTime"
                                  )
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Content</span>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: details?.content,
                            }}
                            className={styles.empname}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewContest;
