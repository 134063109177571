/* eslint-disable no-shadow */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

function TagInput({
  fieldName,
  labelStyle,
  label,
  control,
  errors,
  mandatory,
}) {
  const [localTags, setLocalTags] = useState([]);

  const isContentEmpty = (value) => !value || value.length === 0;

  const handleTagAddition = (newTag) => {
    console.log(newTag);
    if (newTag && !localTags.includes(newTag)) {
      setLocalTags((prevTags) => [...prevTags, newTag.label]);
    }
  };

  return (
    <div className="form-group">
      <label
        className={labelStyle}
        style={{
          fontSize: "14px",
          marginBottom: "0px",
        }}
        htmlFor="tag-input"
      >
        {label}
      </label>
      {mandatory && <span style={{ color: "red", marginLeft: "3px" }}>*</span>}
      <Controller
        name={fieldName}
        control={control}
        rules={{
          validate: (value) =>
            !isContentEmpty(value) || "At least one tag is required",
        }}
        render={({ field: { onChange, value } }) => (
          <Typeahead
            id="tag-input"
            multiple
            selected={value || []}
            onChange={(tags) => {
              const selectedTags = tags.map((tag) => tag);
              onChange(selectedTags);
              const addedTags = selectedTags.filter(
                (tag) => !localTags.includes(tag)
              );
              addedTags.forEach(handleTagAddition);
            }}
            // className={appliedClassName}
            placeholder="Add tags"
            allowNew
            newSelectionPrefix="Add new tag:"
            options={[]}
          />
        )}
      />
      {errors[fieldName] && (
        <p
          style={{
            color: "red",
            marginTop: "5px",
            fontSize: "14px",
            fontFamily: "SFUIDisplayRegular",
          }}
          className="error-message"
        >
          {errors[fieldName].message}
        </p>
      )}
    </div>
  );
}

TagInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  control: PropTypes.element.isRequired,
  labelStyle: PropTypes.string,
  label: PropTypes.string.isRequired,
  errors: PropTypes.object,
  mandatory: PropTypes.bool,
};

TagInput.defaultProps = {
  labelStyle: "",
  errors: {},
  mandatory: false,
};

export default TagInput;
