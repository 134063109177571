import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { SpinnerDotted } from "spinners-react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import TextInput from "../../components/TextField/TextField";
// import SelectBox from "../../components/SelectBox/SelectBox";
import { Entry } from "../../redux/entrySlice";
// import DatePickerComponent from "../../components/DatePicker/DatePicker";
import { TableContext } from "../../contexts/tableContext";
import styles from "./edit.module.scss";
import Editor from "../../components/Editor/Editor";

function CmsEdit() {
  let cmsId;
  const [submiting, setSubmitting] = useState(false);
  const { dashboardStyle } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const params = useParams();
  const [cms, setCms] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader("Edit CMS");
    cmsId = params.cmsId;

    const actionData = {};
    actionData.actionUrl = `cms/${cmsId}`;
    actionData.actionMethod = "get";
    dispatch(Entry(actionData)).then((resp) => {
      setCms(resp.payload.data.cms);
      reset(resp.payload.data.cms);
    });
  }, []);

  const convertQuillStylesToCss = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const quillClasses = [
      "ql-align-right",
      "ql-align-center",
      "ql-align-justify",
    ];

    quillClasses.forEach((quillClass) => {
      const elements = doc.querySelectorAll(`.${quillClass}`);
      elements.forEach((element) => {
        element.classList.remove(quillClass);
        element.style.textAlign = quillClass.replace("ql-align-", "");
      });
    });

    const ulElements = doc.querySelectorAll("ul");
    ulElements.forEach((ul) => {
      ul.setAttribute("style", "list-style-type: disc");
    });

    const olElements = doc.querySelectorAll("ol");
    olElements.forEach((ol) => {
      ol.setAttribute("style", "type: 1");
    });

    return doc.body.innerHTML;
  };

  function onSubmitdata(data) {
    if (data.name.trim().length === 0) {
      document.getElementById("name").focus();
    } else {
      setSubmitting(true);
      setError(null);
      const convertedContent = convertQuillStylesToCss(data.content);
      const apiData = {
        name: data.name,
        alias: data.alias,
        content: convertedContent,
      };
      data.actionUrl = `cms/${params.cmsId}`;
      data.actionMethod = "patch";
      data.apiData = apiData;
      dispatch(Entry(data)).then((resp) => {
        setSubmitting(false);
        if (resp.payload.code === 200) {
          navigate(-1);
          toast.success(`${resp.payload.data.cms.name} published successfully`);
        } else if (
          resp.payload.code === 401 ||
          resp.payload.code === 400 ||
          resp.payload.code === 406
        ) {
          setError(resp.payload.message);
        } else {
          navigate(-1);
        }
      });
    }
  }

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5 edituserform">
            {cms.length !== 0 ? (
              <form onSubmit={handleSubmit(onSubmitdata)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    {cms && (
                      <div>
                        <TextInput
                          className={styles.inputbox}
                          classNamedark={styles.inputbox1}
                          labelstyle={styles.formlabel}
                          label="Name"
                          fieldName="name"
                          placeHolder="Enter Name"
                          register={register}
                          errors={errors}
                          isRequired
                          mandatory
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <Editor
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelStyle={styles.formlabel}
                      label="Content"
                      fieldName="content"
                      placeHolder="Enter your content"
                      errors={errors}
                      control={control}
                      mandatory
                    />
                  </div>
                </div>
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? "Please wait.." : "Publish"}
                />
                {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
              </form>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SpinnerDotted color="#3498db" thickness={70} enabled />
              </div>
            )}
          </div>
        </div>
        <ToastContainer autoClose={8000} />
      </div>
    </div>
  );
}

export default CmsEdit;
