/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import DateAndTimePicker from "react-datetime-picker";
import styles from "./DateAndTimePicker.module.scss";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

function DateAndTimePickerComponent({
  control,
  name,
  isRequired,
  errors,
  className,
  classNamedark,
  label,
}) {
  let appliedClassName = className;

  if (localStorage.getItem("theme") === "dark") {
    appliedClassName = classNamedark || className;
  }

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: {
            value: isRequired,
            message: `${label} is required`,
          },
        }}
        render={({ field }) => (
          <div>
            <DateAndTimePicker
              className={appliedClassName}
              id={styles.datepicker}
              onChange={(date) => field.onChange(date)}
              value={
                typeof field.value === "string"
                  ? new Date(field.value)
                  : field.value
              }
              disableClock
              format="d-MM-yyyy h:mm a"
              amPmAriaLabel="AM/PM"
            />
          </div>
        )}
      />
      {errors[name] && (
        <p
          style={{
            color: "red",
            marginTop: "5px",
            fontSize: "14px",
            fontFamily: "SFUIDisplayRegular",
          }}
        >
          {errors[name].message}
        </p>
      )}
    </>
  );
}

DateAndTimePickerComponent.propTypes = {
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  className: PropTypes.string,
  classNamedark: PropTypes.string,
  label: PropTypes.string,
};

DateAndTimePickerComponent.defaultProps = {
  isRequired: false,
  classNamedark: null,
  className: "default-input-class",
  label: "Date",
};

export default DateAndTimePickerComponent;
