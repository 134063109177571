/* eslint-disable react/function-component-definition */
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import { SpinnerRoundFilled } from "spinners-react";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import styles from "./videoView.module.scss";
import envValues from "../../enviornment";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

const VideoPopUp = React.memo(({ dataId, handleClose }) => {
  const { formthemeStyle } = useContext(TableContext);
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  let statusCode;
  const getContestDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/api/video/${dataId}`, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.VideoUpload.videoStreamableURL);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  useEffect(() => {
    getContestDetails();
  }, []);
  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <span
          className={styles.closeicon}
          role="button"
          tabIndex={0}
          onClick={handleClose}
          onKeyPress={handleClose}
        >
          x
        </span>
        {loading ? (
          <SpinnerRoundFilled style={{ marginLeft: "50%" }} />
        ) : (
          <Container fluid>
            <div>
              {!details || details.length === 0 ? (
                <p>No Preview Available</p>
              ) : (
                <Row>
                  <Col lg={12}>
                    <VideoPlayer value={details} />
                  </Col>
                </Row>
              )}
            </div>
          </Container>
        )}
      </div>
    </div>
  );
});

VideoPopUp.propTypes = {
  dataId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default VideoPopUp;
