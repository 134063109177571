/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import styles from "./index.module.scss";
import CustomTable from "../../components/CustomTable/CustomTable";
import { REACT_APP_API_FETCH_CMS_APPROVED } from "../../redux/apiConstants";
import { TableContext } from "../../contexts/tableContext";

const ApprovedCms = React.memo(() => {
  const { setDashboardHeader } = useContext(TableContext);
  useEffect(() => {
    setDashboardHeader("Audit Report");
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.indexdiv}>
      <CustomTable
        urlParam={REACT_APP_API_FETCH_CMS_APPROVED}
        section="audit"
        toggleButton={{ show: true, field: "active" }}
        deleteCondition={{ checkCondition: false, apiUrl: "www.google.com" }}
      />
    </div>
  );
});
export default ApprovedCms;
