/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function Editor({
  fieldName,
  className,
  labelStyle,
  label,
  control,
  errors,
  mandatory,
}) {
  const appliedLabelStyle = labelStyle;
  const appliedClassName = className;
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  const isContentEmpty = (value) => {
    const trimmedValue = value.trim();
    return trimmedValue === "" || trimmedValue === "<p><br></p>";
  };

  return (
    <div className="form-group">
      <label className={appliedLabelStyle} htmlFor="editor">
        {label}
      </label>
      {mandatory && <span style={{ color: "red", marginLeft: "3px" }}>*</span>}
      <Controller
        name={fieldName}
        control={control}
        rules={{
          validate: (value) => !isContentEmpty(value) || "Content is required",
        }}
        render={({ field: { onChange, value } }) => (
          <ReactQuill
            id="editor"
            value={value || ""}
            modules={modules}
            onChange={(content) => onChange(content)}
            className={appliedClassName}
          />
        )}
      />
      {errors[fieldName] && (
        <p
          style={{
            color: "red",
            marginTop: "5px",
            fontSize: "14px",
            fontFamily: "SFUIDisplayRegular",
          }}
          className="error-message"
        >
          {errors[fieldName].message}
        </p>
      )}
    </div>
  );
}

Editor.propTypes = {
  fieldName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  control: PropTypes.element.isRequired,
  labelStyle: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.element.isRequired,
};

export default Editor;
