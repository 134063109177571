/* eslint-disable no-constant-condition */
/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import PropTypes, { bool } from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { TableContext } from "../../contexts/tableContext";
// import { SpinnerRoundFilled } from 'spinners-react';
import ActionButtons from "../ActionsButtons/ActionButtons";
import Pagination from "../Pagination/Pagination";
import ToggleButtonOnOff from "../ToggleButton/ToggleButton";
// import ActionSelection from '../ActionsButtons/ActionSelection';
import styles from "./CustomTable.module.scss";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getCookies } from "../../hooks/useCookies";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import ApproveReject from "../ApproveRejectButtons/ApproveReject";
import envValues from "../../enviornment";
import useToggle from "../../hooks/useToggle";
import { Entry } from "../../redux/entrySlice";
import ExportToExcel from "../Export/ExportToExcel";
import DateRangeFilter from "../DateRangeFilter/DateRangeFilter";

const CustomTable = React.memo(
  ({
    urlParam,
    viewPopUp,
    viewVideo,
    toggleButton,
    section,
    dataPerPage,
    deleteCondition,
  }) => {
    let url;
    const { dashboardStyle } = useContext(TableContext);
    const navigate = useNavigate();
    const { bodyStyle } = useContext(TableContext);
    const params = useParams();
    const [addButtonPermissions, setAddButtonPermissions] = useState({});
    const { bodyheader } = useContext(TableContext);
    const { searchboxStyle } = useContext(TableContext);
    const { tableheadStyle } = useContext(TableContext);
    const { tablebodyStyle } = useContext(TableContext);
    const [tableData, setTableData] = useState([]);
    const dispatch = useDispatch();
    const {
      pageNumView,
      setPageNumView,
      deletedData,
      approved,
      rejected,
      setDeletedData,
    } = useContext(TableContext);
    // new
    const [checkedState, setCheckedState] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [open, setOpen] = useState(false);
    const [daterange, setDaterange] = useState("");
    const token = `Bearer ${getCookies("Token")}`;
    const [sorting, setSorting] = useState([{ col: "none", sortType: "none" }]);
    const [paginationData, setPaginationData] = useState([
      { page: 0, totalResult: 0, totalPages: 0 },
    ]);
    const [search, setSearchTerm] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [timer, setTimer] = useState(null);
    const [columns, setColumns] = useState([]);
    const [finalist, setFinalist] = useToggle();
    // const [deletedData, setDeletedData] = useState(null);
    const [selected, setselected] = useState([]);
    const [stockCount, setStockCount] = useState(0);
    const [tableLoading, setTableLoading] = useState(true);
    const dateFormat = getCookies("dateFormat");
    const sectionWaitlist = "waitlist";
    const selectText = "Select";
    let response;
    let statusCode;
    const handleOnChange = (id, position) => {
      if (section === "finalist" || section === "finalist-videos") {
        const updatedCheckedState = checkedState.map((item, indexed) =>
          indexed === position ? !item : false
        );
        setCheckedState(updatedCheckedState);
        setselected(id);
        if (selected === id) {
          setselected([]);
        }
      } else {
        const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);

        // Update selected ids
        let newSelected = [...selected];
        if (updatedCheckedState[position]) {
          newSelected.push(id);
        } else {
          newSelected = newSelected.filter((selectedId) => selectedId !== id);
        }

        setselected(newSelected);
      }
    };

    const actionAdd = () => {
      navigate(`/${section.toLowerCase()}/create/`);
    };
    const handleOnChangeAll = () => {
      setCheckedAll(!checkedAll);
      const newarray = [];
      if (checkedAll === false) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    };
    const onChange = (ranges) => {
      // ranges ...
      if (
        moment(ranges.startDate).format("MM-DD-YYYY") !==
        moment(ranges.endDate).format("MM-DD-YYYY")
      ) {
        setOpen(false);
      } else if (ranges.startDate === "" && ranges.endDate === "") {
        setOpen(false);
      }
      const dateRange = JSON.stringify(ranges);
      setDaterange(dateRange);
    };
    const getUsers = async () => {
      setTableLoading(true);
      url = urlParam;
      if (
        daterange &&
        daterange.startDate !== null &&
        daterange.endDate !== null
      ) {
        url += `daterange=${encodeURIComponent(daterange)}&`;
      } else {
        url = urlParam;
      }
      url += `page=${
        pageNumView > 1 ? pageNumView : pageNum
      }&limit=${dataPerPage}`;
      if (sorting.sortType && sorting.sortType !== "none") {
        url += `&sortBy=${sorting.col}:${sorting.sortType}`;
      }
      if (search !== null) {
        const encodedSearchTerm = encodeURIComponent(search);
        url += `&searchBy=${encodedSearchTerm}`;
      }
      console.log(url);
      axios
        .get(url, {
          method: "GET",
          headers: { Authorization: token },
        })
        .then((resp) => {
          setTableLoading(false);
          statusCode = resp.status;
          return resp;
        })
        .then((res) => {
          if (statusCode === 200) {
            response = res.data.data.result;
            setTableData(response.results);
            setPaginationData({
              page: response.page,
              totalResult: response.totalResults,
              totalPages: response.totalPages,
            });
            setCheckedState(new Array(response.results.length).fill(false));
            setColumns(response.Displayfields);
          }
        })
        .catch((err) => {
          setTableLoading(false);
          return err;
        });
      if (pageNumView > 1) setPageNumView(1);
    };
    useEffect(() => {
      setSearchTerm(null);
      setPageNum(1);
    }, []);
    useEffect(() => {
      const temp = section.replace(/\s+/g, "").toLowerCase();
      console.log(temp);
      const permissions = getCookies("USERPERMISSION");
      console.log(permissions);
      permissions.forEach((val) => {
        if (val.section.toLowerCase() === temp) {
          setAddButtonPermissions({
            view: val.view ? val.view : false,
            edit: val.edit ? val.edit : false,
            create: val.create ? val.create : false,
            delete: val.delete ? val.delete : false,
            list: !!(val.view || val.edit || val.create || val.delete),
          });
        } else if (val.submenu) {
          val.submenu.forEach((element) => {
            if (element.section.toLowerCase() === temp) {
              setAddButtonPermissions({
                view: element.view ? element.view : false,
                edit: element.edit ? element.edit : false,
                create: element.create ? element.create : false,
                delete: element.delete ? element.delete : false,
                list: !!(
                  element.view ||
                  element.edit ||
                  element.create ||
                  element.delete
                ),
              });
            }
          });
        }
      });
      getUsers();
      const newarray = [];
      if (checkedAll) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    }, [
      pageNum,
      search,
      sorting,
      deletedData,
      approved,
      rejected,
      finalist,
      daterange,
    ]);
    const setSort = (param) => () => {
      if (param === "date") {
        param = "createdAt";
      }
      setPageNum(1);
      switch (sorting.sortType) {
        case "none":
          setSorting({ col: param, sortType: "asc" });
          break;
        case "asc":
          setSorting({ col: param, sortType: "desc" });
          break;
        case "desc":
          setSorting({ col: param, sortType: "none" });
          break;
        default:
          setSorting({ col: param, sortType: "asc" });
          break;
      }
    };
    const handleKeyPress = (event) => {
      const allowedCharacters = /[a-zA-Z0-9&'.,@ ]/;
      const inputChar = String.fromCharCode(event.charCode);

      if (!allowedCharacters.test(inputChar)) {
        event.preventDefault();
      }
    };
    const onFilterTextChange = (e) => {
      const even = e;
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          const { value } = even.target;
          if (value.trim().length > 0) {
            setPageNum(1);
            const trimmedValue = even.target.value.trim().replace(/\s+/g, " ");
            setSearchTerm(trimmedValue);
          } else {
            setSearchTerm(null);
          }
          even.target.value = value.trim().replace(/\s+/g, " ");
        }, 1000)
      );
    };
    const handleAddToFinalist = async () => {
      setFinalist(true);
      try {
        const resp = await axios.patch(
          `${envValues.REACT_APP_API_ENDPOINT}/api/video/disqualify`,
          {
            ids: selected,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (resp.status === 200) {
          toast.success("Disqualified");
        }
      } catch (error) {
        console.log(error);
      }
    };

    const selectWinner = (data) => {
      confirmAlert({
        title: "",
        message: "Are you sure you want to publish this video as winner?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `contest/select-winner`;
              actionData.actionMethod = "patch";
              actionData.apiData = { videoId: selected };
              dispatch(Entry(actionData)).then(() => {
                toast.success("Winner Selected Successfully");
                navigate(-1);
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ],
      });
    };

    const truncateText = (text, maxLength = 35) => {
      if (typeof text !== "string") return text;
      const capitalizedText = text.charAt(0).toUpperCase() + text.slice(1);
      return capitalizedText.length > maxLength
        ? `${capitalizedText.substring(0, maxLength)}...`
        : capitalizedText;
    };
    return (
      <div>
        {addButtonPermissions.list && (
          <div
            className={styles[dashboardStyle]}
            style={{ minHeight: "100vh" }}
            id={styles.dashboardcont2}
          >
            <div className="container-fluid" id={styles.container}>
              <div className="row">
                {section === "video" && (
                  <div className="col-lg-5 col-md-12 col-sm-12">
                    <DateRangeFilter
                      onChange={onChange}
                      open={open}
                      setOpen={setOpen}
                    />
                  </div>
                )}
                <div className="col-lg-3 col-md-12 col-sm-12" />
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className={styles.search_box}>
                    {section === "cms" || section === "current-contest" ? (
                      <p />
                    ) : (
                      <input
                        className={styles[searchboxStyle]}
                        id={styles.searchbar}
                        type="search"
                        onChange={onFilterTextChange}
                        onKeyPress={handleKeyPress}
                        placeholder={
                          section === "user"
                            ? "Search by Name, Email or Phone"
                            : section === sectionWaitlist
                            ? "Search by Email"
                            : section === "contest" ||
                              section === "past-contest"
                            ? "Search by Contest Name"
                            : section === "video" ||
                              section === "approved" ||
                              section === "approved-videos" ||
                              section === "finalist-videos"
                            ? "Search by Username or Invention Name"
                            : section === "cms"
                            ? "Search by Name"
                            : section === "audit"
                            ? "Search by Name or Email"
                            : section === "reported" || section === "concluded"
                            ? "Search by Title, Category, Reported By"
                            : section === "appupdate"
                            ? "Search by description, version or platform"
                            : section === "connect" ||
                              section === "buy-interest"
                            ? "Search by Name, Email, Phone or Address"
                            : "Search"
                        }
                        // value={search}
                        readOnly={tableLoading}
                      />
                    )}
                    <div
                      className={
                        tableLoading ? "spinner-border spinner-border-sm" : ""
                      }
                      id={styles.spinner}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12" />
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {section === "finalist" ? (
                    <div className={styles.sectionright}>
                      {selected.length !== 0 && (
                        <button
                          className={styles.tablebtn2}
                          type="button"
                          onClick={() => {
                            handleAddToFinalist();
                          }}
                        >
                          Disqualify
                        </button>
                      )}
                    </div>
                  ) : section === "finalist-videos" ? (
                    <div className={styles.sectionright}>
                      {selected.length !== 0 && (
                        <button
                          className={styles.tablebtn1}
                          type="button"
                          onClick={() => {
                            selectWinner();
                          }}
                        >
                          Publish
                        </button>
                      )}
                    </div>
                  ) : section === "audit" ? (
                    <ExportToExcel
                      apiUrl="/cms/audit"
                      fileName="Audit Report"
                      section="audit"
                      disabled={tableData?.length === 0}
                    />
                  ) : section === "buy-interest" ? (
                    <ExportToExcel
                      apiUrl={`/api/video/populate/${params.videoId}?field=buyIntrestUser`}
                      fileName="Buy Interest List"
                      section="buy-interest"
                      disabled={tableData.length === 0}
                    />
                  ) : section === "connect" ? (
                    <ExportToExcel
                      apiUrl={`/api/video/populate/${params.videoId}?field=connectedUsers`}
                      fileName="Connect List"
                      section="connect"
                      disabled={tableData.length === 0}
                    />
                  ) : (
                    <div className={styles.sectionright}>
                      {addButtonPermissions.create && (
                        <button
                          className={styles.tablebtn}
                          type="button"
                          onClick={() => {
                            actionAdd();
                          }}
                        >
                          {" "}
                          <FontAwesomeIcon
                            icon={faPlus}
                            className={styles.add_icon}
                          />
                          Add
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {console.log(columns)}
              <div className="table-responsive" style={{ minHeight: "250px" }}>
                <table className="table table-borderless">
                  {columns.length > 0 && (
                    <thead>
                      <tr className={styles[tableheadStyle]}>
                        {(section === "finalist" ||
                          section === "finalist-videos") && (
                          <th scope="col">{selectText.toUpperCase()}</th>
                        )}
                        {columns.map((item) => (
                          <th scope="col">
                            {" "}
                            {item.view.toUpperCase()}
                            {section === "buy-interest" ||
                            section === "connect" ||
                            section === "finalist" ||
                            section === "current-contest" ? (
                              // eslint-disable-next-line react/jsx-no-useless-fragment
                              <></>
                            ) : (
                              <button
                                className={styles.sortbtn}
                                type="button"
                                aria-label="Sort button"
                                onClick={setSort(item.db)}
                              >
                                {sorting.sortType === "asc" &&
                                sorting.col === item.db ? (
                                  <i className="fa fa-arrow-up" />
                                ) : sorting.sortType === "desc" &&
                                  sorting.col === item.db ? (
                                  <i className="fa fa-arrow-down" />
                                ) : (
                                  <i className="fa fa-sort" />
                                )}
                              </button>
                            )}
                          </th>
                        ))}
                        {section === "connect" || section === "buy-interest" ? (
                          ""
                        ) : Object.values(addButtonPermissions).filter(Boolean)
                            .length === 2 ? (
                          <th scope="col">ACTION</th>
                        ) : (
                          <th scope="col">ACTIONS</th>
                        )}
                      </tr>
                    </thead>
                  )}
                  {Object.keys(tableData) && !tableLoading ? (
                    <tbody>
                      {tableData.map((item, index) => (
                        <tr className={styles[tablebodyStyle]}>
                          {section === "finalist" ? (
                            <th scope="row">
                              <div className="left-section">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${item.db}`}
                                  name={item.id}
                                  value={item.id}
                                  checked={checkedState[index]}
                                  onChange={() =>
                                    handleOnChange(item.id, index)
                                  }
                                />
                              </div>
                            </th>
                          ) : item.isSelected ? (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <></>
                          ) : section === "finalist-videos" ? (
                            <th scope="row">
                              <div className="left-section">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${item.db}`}
                                  name={item.id}
                                  value={item.id}
                                  checked={checkedState[index]}
                                  onChange={() =>
                                    handleOnChange(item.id, index)
                                  }
                                />
                              </div>
                            </th>
                          ) : (
                            ""
                          )}
                          {columns.map((ele) => (
                            <td>
                              {ele.db === toggleButton.field ? (
                                <ToggleButtonOnOff
                                  value={item[ele.db]}
                                  id={item.id}
                                  placeholders={["Active", "Inactive"]}
                                />
                              ) : item[ele.db] === "user" ? (
                                "Viewer"
                              ) : typeof item[ele.db] === "boolean" ? (
                                <h6 className="order_date">
                                  {" "}
                                  String(item[ele.db])
                                </h6>
                              ) : ((ele.db === "startDate" ||
                                  ele.db === "endDate") &&
                                  (section === "contest" ||
                                    section === "past-contest" ||
                                    section === "current-contest")) ||
                                ((section === "video" ||
                                  section === "approved" ||
                                  section === "rejected" ||
                                  section === "finalist" ||
                                  section === "approved-videos" ||
                                  section === "finalist-videos" ||
                                  section === "past-finalist-videos") &&
                                  ele.db === "uploadedTime") ? (
                                DateFormatter(item[ele.db], "toDate")
                              ) : (section === "video" ||
                                  section === "reported") &&
                                ele.db === "status" ? (
                                item[ele.db] === "uploaded" ||
                                item[ele.db] === "pending" ? (
                                  <ApproveReject
                                    section={section}
                                    data={item}
                                    pageNum={pageNum}
                                  />
                                ) : (
                                  truncateText(item[ele.db])
                                )
                              ) : (section === "buy-interest" ||
                                  section === "connect") &&
                                ele.db === "contestId" ? (
                                item[ele.db].contestName
                              ) : (section === "buy-interest" ||
                                  section === "connect") &&
                                ele.db === "user_id" ? (
                                item[ele.db].email
                              ) : (section === "contest" ||
                                  section === "current-contest" ||
                                  section === "past-contest") &&
                                ele.db === "duration" ? (
                                item[ele.db] === 1 ? (
                                  "1 day"
                                ) : (
                                  `${item[ele.db]} days`
                                )
                              ) : item[ele.db] === undefined &&
                                ele.db === "videoRank" ? (
                                "0"
                              ) : item[ele.db] === undefined ? (
                                "N/A"
                              ) : ele.db === "alias" ? (
                                <a
                                  href={`${envValues.FRONTENDURL}${
                                    item[ele.db]
                                  }`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {`${envValues.FRONTENDURL}${item[ele.db]}`}
                                </a>
                              ) : ele.db === "approvedTime" ? (
                                DateFormatter(item[ele.db], "toDate")
                              ) : section === "connect" ||
                                section === "buy-interest" ? (
                                item[ele.db]
                              ) : (
                                truncateText(item[ele.db])
                              )}
                            </td>
                          ))}

                          <td>
                            {section === "connect" ||
                            section === "buy-interest" ? (
                              ""
                            ) : (
                              <ActionButtons
                                data={item}
                                viewPopUp={viewPopUp}
                                viewVideo={viewVideo}
                                section={section}
                                pageNum={pageNum}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <h6>Loading...</h6>
                  )}
                  {tableData.length === 0 && !tableLoading ? (
                    <h6 className={styles.norecord}>No record found</h6>
                  ) : (
                    ""
                  )}
                </table>
              </div>
              <Pagination
                paginationData={paginationData}
                setPageNum={setPageNum}
              />
              <br />
              <br />
            </div>
          </div>
        )}
      </div>
    );
  }
);
CustomTable.propTypes = {
  urlParam: PropTypes.string,
  viewPopUp: PropTypes.bool,
  viewVideo: PropTypes.bool,
  toggleButton: PropTypes.oneOfType([PropTypes.object]),
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  section: PropTypes.string,
  dataPerPage: PropTypes.number,
};
CustomTable.defaultProps = {
  viewPopUp: false,
  viewVideo: false,
  toggleButton: { show: false, field: "none" },
  deleteCondition: { checkCondition: false, apiUrl: "none" },
  section: "User",
  urlParam: null,
  dataPerPage: 10,
};
export default CustomTable;
