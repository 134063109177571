import React, { Suspense, lazy } from "react";
import { SpinnerDotted } from "spinners-react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Outerlayout from "../layouts/outerLayout";
import Innerlayout from "../layouts/innerLayout";
import { getCookies, removeCookies } from "../hooks/useCookies";
import Settings from "../pages/settings/settings";
import ViewUsers from "../pages/users/viewUser";
import UserEdit from "../pages/users/editUser";
// import UserIndex from '../pages/users/index';
import UserCreate from "../pages/users/createUser";
import Preferences from "../pages/settings/preferences";
import CreateRoles from "../pages/roles/createRoles";
import RolesIndex from "../pages/roles/index";
import EditRoles from "../pages/roles/editRoles";
import SettingsApi from "../pages/settings/SettingsApiPage";
import SettingsLayout from "../pages/settings/settingsLayout";
import PageNotFound from "../pages/404/pageNotFound";
import PermissionDenied from "../pages/permission/permission";
import ProfileLayout from "../pages/profile/profileLayout";
import ContestIndex from "../pages/contest";
import ContestCreate from "../pages/contest/createContest";
import ContestEdit from "../pages/contest/editContest";
import ViewContest from "../pages/contest/viewContest";
import PastContestIndex from "../pages/past-contest";
import ViewPastContest from "../pages/past-contest/viewContest";
import VideoIndex from "../pages/video";
import ViewVideo from "../pages/video/viewVideo";
import ApprovedVideoIndex from "../pages/approved-videos";
import ViewApprovedVideo from "../pages/approved-videos/viewVideo";
import BuyInterestIndex from "../pages/buy-interest";
import ViewBuyInterest from "../pages/buy-interest/viewBuyInterest";
import ConnectIndex from "../pages/connect-list";
import ViewConnectList from "../pages/connect-list/viewConnectList";
import FinalistIndex from "../pages/finalist";
import ViewFinalist from "../pages/finalist/viewConnectList";
import ReportedIndex from "../pages/reportedVideos";
import ViewReportedVideo from "../pages/reportedVideos/viewVideo";
import ConcludedIndex from "../pages/concluded";
import ViewConcluded from "../pages/concluded/viewVideo";
import CurrentContestIndex from "../pages/current-contest";
import ViewCurrentContest from "../pages/current-contest/viewContest";
import ApprovedIndex from "../pages/approvedVideosByContestId";
import FinalistVideoIndex from "../pages/finalist/finalistVideos";
import ViewApprovedVideoByContest from "../pages/approvedVideosByContestId/viewVideo";
import CmsIndex from "../pages/cms";
import CmsCreate from "../pages/cms/create";
import EditCms from "../pages/cms/edit";
import ViewCms from "../pages/cms/view";
import PastFinalistVideoIndex from "../pages/finalist/pastFinalistVideos";
import ViewPastFinalist from "../pages/finalist/pastConnectList";
import ApprovedCms from "../pages/audit";
import ViewApprovedCms from "../pages/audit/viewUser";
import AppUpdateIndex from "../pages/app-updates";
import AppUpdateCreate from "../pages/app-updates/createAppUpdate";
import ViewAppUpdate from "../pages/app-updates/viewContest";

const Login = lazy(() => import("../pages/logIn/logIn"));
const UserIndex = lazy(() => import("../pages/users/index"));
const UserVerification = lazy(() => import("../pages/verify/userVerfication"));
const Register = lazy(() => import("../pages/register/register"));
const Forgetpassword = lazy(() => import("../pages/passwords/forgetPassword"));
const ResetPassword = lazy(() => import("../pages/passwords/resetPassword"));
const Dashboard = lazy(() => import("../pages/dashboard/dashboard"));
const MyForm = lazy(() =>
  import("../components/PhoneNumberBox/PhoneNumberBox")
);
// const PageNotFound = lazy(() => import('../pages/404/pageNotFound'));
const ProfilePage = lazy(() => import("../pages/profile/profilepage"));
const ChangePassword = lazy(() => import("../pages/profile/changePassword"));
const ProfileEdit = lazy(() => import("../pages/profile/profileEdits"));
const Faq = lazy(() => import("../cms/faq"));
const ViewRole = lazy(() => import("../pages/roles/view"));
const WaitListIndex = lazy(() => import("../pages/waitList/index"));
const NotificationsIndex = lazy(() => import("../pages/notifications/index"));
const RejectededVideoIndex = lazy(() =>
  import("../pages/rejected-videos/index")
);
const RejectededVideoDetails = lazy(() =>
  import("../pages/rejected-videos/viewVideo")
);
export default function Routers() {
  return (
    <Router>
      <Routes>
        <Route element={<Outerlayout />}>
          <Route
            exact
            path="/"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: "50%",
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: "50%",
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/verify-email"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <UserVerification />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/register"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: "50%",
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Register />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/forgetpassword"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: "50%",
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Forgetpassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reset/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route exact path="/" element={<Dashboard />} /> */}
          <Route
            exact
            path="/reset"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
        </Route>
        <Route element={<Innerlayout />}>
          <Route
            exact
            path="/roles"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <RolesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CreateRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/edit/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <EditRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/viewdetails/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewRole />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <Dashboard />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/notifications"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "400px",
                      color: "#39979d",
                    }}
                  >
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <NotificationsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/waitlist"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "400px",
                      color: "#39979d",
                    }}
                  >
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <WaitListIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/viewdetails/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewUsers />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <UserCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/edit/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <UserEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/text"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <MyForm />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/audit"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: "250px", marginLeft: "300px" }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ApprovedCms />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/audit/viewdetails/:cmsApprovedId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{ marginTop: "250px", marginLeft: "300px" }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewApprovedCms />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <UserIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/appupdate"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <AppUpdateIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/appupdate/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <AppUpdateCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/appupdate/viewdetails/:appId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewAppUpdate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/cms"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CmsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/cms/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CmsCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/cms/edit/:cmsId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <EditCms />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/cms/viewdetails/:cmsId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewCms />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/contest"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ContestIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/video"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <VideoIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/buy-interest"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <BuyInterestIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/connect"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ConnectIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/finalist/:contestId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <FinalistIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reported"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ReportedIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/current-contest"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CurrentContestIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/current-contest/viewdetails/:contestId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewCurrentContest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/concluded"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ConcludedIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/buy-interest/viewdetails/:videoId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewBuyInterest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/concluded/viewdetails/:videoId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewConcluded />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/connect/viewdetails/:videoId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewConnectList />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/finalist/viewdetails/:videoId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewPastFinalist />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/finalist-videos/viewdetails/:videoId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewFinalist />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/past-finalist-videos/viewdetails/:videoId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewPastFinalist />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reported/viewdetails/:videoId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewReportedVideo />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/approved"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ApprovedVideoIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/rejected"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <RejectededVideoIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/rejected/viewdetails/:videoId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <RejectededVideoDetails />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/past-contest"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <PastContestIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/approved-videos/:contestId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ApprovedIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/finalist-videos/:contestId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <FinalistVideoIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/past-finalist-videos/:contestId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <PastFinalistVideoIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/past-contest/viewdetails/:contestId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewPastContest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/contest/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ContestCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/contest/edit/:contestId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ContestEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/current-contest/edit/:contestId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ContestEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/contest/viewdetails/:contestId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewContest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/video/viewdetails/:videoId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewVideo />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/approved/viewdetails/:videoId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewApprovedVideo />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/approved-videos/viewdetails/:videoId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewApprovedVideoByContest />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route element={<SettingsLayout />}>
            <Route
              exact
              path="/settings"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <Settings />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/preferences"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <Preferences />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/api"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <SettingsApi />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
          <Route element={<ProfileLayout />}>
            <Route
              exact
              path="/profile"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <ProfilePage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/profile-edit"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <ProfileEdit />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/cms"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: "250px", marginLeft: "300px" }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <Faq />
                  </ProtectedRoute>
                </Suspense>
              }
            />

            <Route
              exact
              path="/changePassword"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <ChangePassword />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          exact
          path="*"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{
                      marginTop: "250px",
                      marginLeft: "300px",
                      color: "#39979d",
                    }}
                  />
                </div>
              }
            >
              {" "}
              <PageNotFound />
            </Suspense>
          }
        />
        <Route
          exact
          path="/permission"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{
                      marginTop: "250px",
                      marginLeft: "300px",
                      color: "#39979d",
                    }}
                  />
                </div>
              }
            >
              <ProtectedRoute>
                {" "}
                <PermissionDenied />
              </ProtectedRoute>
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

// eslint-disable-next-line react/prop-types
function ProtectedRoute({ children }) {
  if (document.cookie && getCookies("Token")) {
    if (getCookies("USERPERMISSION")) {
      return children;
    }
    removeCookies("Token");
    removeCookies("refreshToken");
    sessionStorage.setItem("cookiesCleared", true);
    return <Navigate to="/login" />;
  }
  sessionStorage.setItem("cookiesCleared", true);
  return <Navigate to="/login" />;
}

// eslint-disable-next-line react/prop-types
function UnProtectedRoute({ children }) {
  return getCookies("Token") ? <Navigate to="/dashboard" /> : children;
}
