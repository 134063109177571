/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useContext } from "react";
import "react-date-range/dist/styles.css"; // main style file
import { useNavigate, useParams } from "react-router-dom";
import "react-date-range/dist/theme/default.css"; // theme css file
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./index.module.scss";
import CustomTable from "../../components/CustomTable/CustomTable";
import { TableContext } from "../../contexts/tableContext";
import envValues from "../../enviornment";

const FinalistVideoIndex = React.memo(() => {
  const { setDashboardHeader } = useContext(TableContext);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setDashboardHeader("Finalist List");
    window.scrollTo(0, 0);
  }, [setDashboardHeader]);

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div className={styles.indexdiv}>
      <FontAwesomeIcon
        icon={faArrowLeft}
        value="Back"
        onClick={handleClick}
        className={styles.arrowback}
      />
      <button onClick={handleClick} className={styles.backButton}>
        Back
      </button>
      <CustomTable
        urlParam={`${envValues.REACT_APP_API_ENDPOINT}/api/video/finalist-videos?contestId=${params.contestId}&`}
        section="finalist-videos"
        deleteCondition={{ checkCondition: false, apiUrl: "www.google.com" }}
      />
    </div>
  );
});

export default FinalistVideoIndex;
