/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import ReactHlsPlayer from "react-hls-player";

function VideoPlayer({
  fieldName,
  className,
  labelstyle,
  label,
  value,
  labeldarkstyle,
  classNamedark,
}) {
  let classNames = labelstyle;
  let classNamess = className;

  if (localStorage.getItem("theme") === "dark") {
    classNames = labeldarkstyle;
    classNamess = classNamedark;
  }
  return (
    <div className="form-group">
      <label htmlFor={fieldName} className={classNames ?? labelstyle}>
        {label ?? ""}
      </label>
      <ReactHlsPlayer
        className={classNamess ?? className}
        src={value}
        autoPlay
        controls
        width="100%"
        height="400px"
      />
    </div>
  );
}

VideoPlayer.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  labelstyle: PropTypes.string,
  fieldName: PropTypes.string,
  value: PropTypes.string,
  labeldarkstyle: PropTypes.string,
  classNamedark: PropTypes.string,
};

VideoPlayer.defaultProps = {
  label: "",
  className: "",
  labelstyle: "",
  fieldName: "",
  value: "",
  labeldarkstyle: "",
  classNamedark: "",
};

export default VideoPlayer;
