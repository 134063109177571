/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import TableViewPopup from "../TableViewPopup/TableViewPopup";
import styles from "./ActionButtons.module.scss";
import useToggle from "../../hooks/useToggle";
import { Entry } from "../../redux/entrySlice";
import { TableContext } from "../../contexts/tableContext";
import { getCookies } from "../../hooks/useCookies";
import VideoPopUp from "../VideoViewPopUp/videoView";

function ActionButtons({
  data,
  section,
  viewPopUp,
  viewVideo,
  deleteCondition,
  pageNum,
}) {
  const { actionbtnStyle, setPageNumView } = useContext(TableContext);
  const [actionPermissions, setActionPermission] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, setDeletemessage] = useState();
  const deleteMessage = `Are you sure you want to delete this?`;
  const [canDelete, setCanDelete] = useState(true);
  const { setDeletedData } = useContext(TableContext);
  const [showTablePopUp, setShowTablePopUp] = useToggle();
  const [showVideoPopUp, setShowVideoPopUp] = useToggle();
  const actionView = (param) => () => {
    const encodeText = param.id;
    if (viewPopUp) {
      setShowTablePopUp(true);
    } else {
      setPageNumView(pageNum);
      navigate(`/${section}/viewdetails/${encodeText}`);
    }
  };

  const actionViewVideo = () => {
    if (viewVideo) {
      setShowVideoPopUp(true);
    }
  };
  const actionEdit = (params) => {
    setPageNumView(pageNum);
    const encodeText = params.id;
    navigate(`/${section}/edit/${encodeText}`);
  };
  const handleDelete = (params) => {
    if (section === "contest" || section === "past-contest") {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: deleteMessage,
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  const actionData = {};
                  actionData.actionUrl = `contest/${params.id}`;
                  actionData.actionMethod = "delete";
                  dispatch(Entry(actionData)).then(() => {
                    toast.success("Successfully Deleted");
                    setDeletedData(`data last deleted is ${params.id}`);
                  });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    } else if (
      section === "video" ||
      section === "buy-interest" ||
      section === "connect" ||
      section === "approved"
    ) {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: deleteMessage,
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  const actionData = {};
                  actionData.actionUrl = `api/video/${params.id}`;
                  actionData.actionMethod = "delete";
                  dispatch(Entry(actionData)).then(() => {
                    toast.success("Successfully Deleted");
                    setDeletedData(`data last deleted is ${params.id}`);
                  });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    } else {
      confirmAlert({
        title: canDelete ? "" : "Oops!!",
        message: deleteMessage,
        buttons: canDelete
          ? [
              {
                label: "Yes",
                onClick: () => {
                  const actionData = {};
                  actionData.actionUrl = `${section}/${params.id}`;
                  actionData.actionMethod = "delete";
                  dispatch(Entry(actionData)).then(() => {
                    toast.success("Successfully Deleted");
                    setDeletedData(`data last deleted is ${params.id}`);
                  });
                },
              },
              {
                label: "No",
                // onClick: () => alert("Click No")
              },
            ]
          : [
              {
                label: "Ok",
                // onClick: () => alert("Click No")
              },
            ],
      });
    }
  };
  useEffect(() => {
    const temp = section.replace(/\s+/g, "").toLowerCase();
    const permissions = getCookies("USERPERMISSION");
    permissions.forEach((val) => {
      if (val.section.toLowerCase() === temp) {
        setActionPermission({
          view: val.view ? val.view : false,
          edit: val.edit ? val.edit : false,
          create: val.create ? val.create : false,
          delete: val.delete ? val.delete : false,
          list: !!(val.view || val.edit || val.create || val.delete),
        });
      } else if (val.submenu) {
        val.submenu.forEach((element) => {
          if (element.section.toLowerCase() === temp) {
            setActionPermission({
              view: element.view ? element.view : false,
              edit: element.edit ? element.edit : false,
              create: element.create ? element.create : false,
              delete: element.delete ? element.delete : false,
              list: !!(
                element.view ||
                element.edit ||
                element.create ||
                element.delete
              ),
            });
          }
        });
      }
    });
    if (deleteCondition.checkCondition) {
      setCanDelete(false);
      setDeletemessage("Sorry you cant delete this data");
    }
  }, []);
  return (
    <>
      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" id={styles.btnflex}>
        {actionPermissions.view && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.actionbtn}
            type="button"
            onClick={actionView(data)}
          >
            {" "}
            <i className="fa fa-eye" />
          </button>
        )}
        {actionPermissions.edit &&
          (section === "approved" ||
          section === "video" ||
          section === "finalist" ||
          section === "reported" ||
          section === "concluded" ||
          section === "approved-videos" ||
          section === "finalist-videos" ||
          section === "past-finalist-videos" ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          ) : (
            <button
              className={styles[actionbtnStyle]}
              id={styles.actionbtn}
              type="button"
              onClick={() => {
                actionEdit(data);
              }}
            >
              {" "}
              <i className="fa fa-edit" />
            </button>
          ))}
        {(actionPermissions.delete && section === "reported") ||
        section === "concluded" ||
        section === "current-contest" ||
        section === "video" ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        ) : actionPermissions.delete ? (
          <button
            className={styles[actionbtnStyle]}
            id={styles.actionbtn}
            type="button"
            onClick={() => {
              handleDelete(data);
            }}
          >
            {" "}
            <i className="fa fa-trash" />
          </button>
        ) : (
          <></>
        )}

        {(section === "approved" ||
          section === "video" ||
          section === "rejected") && (
          <button
            className={styles[actionbtnStyle]}
            id={styles.actionbtn}
            type="button"
            onClick={actionViewVideo}
          >
            {" "}
            <i className="fa fa-play" />
          </button>
        )}
      </div>
      {showTablePopUp ? (
        <TableViewPopup
          dataId={data.id}
          handleClose={setShowTablePopUp}
          section={section}
        />
      ) : null}
      {showVideoPopUp ? (
        <VideoPopUp dataId={data.id} handleClose={setShowVideoPopUp} />
      ) : null}
    </>
  );
}

ActionButtons.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  section: PropTypes.string.isRequired,
  viewPopUp: PropTypes.bool.isRequired,
  viewVideo: PropTypes.bool.isRequired,
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  pageNum: PropTypes.number,
};
ActionButtons.defaultProps = {
  deleteCondition: { checkCondition: false, apiUrl: "none" },
  pageNum: 1,
};
export default ActionButtons;
