/* eslint-disable react/function-component-definition */
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "react-bootstrap";
import Avatar from "react-avatar";
import axios from "axios";
import { SpinnerRoundFilled } from "spinners-react";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import styles from "./ViewUsersPopup.module.scss";
import envValues from "../../enviornment";

const ViewUsersPopUp = React.memo(({ dataId, handleClose }) => {
  const { formthemeStyle } = useContext(TableContext);
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  let statusCode;
  const getContestDetails = async () => {
    setLoading(true);
    axios
      .get(
        `${envValues.REACT_APP_API_ENDPOINT}/api/video/populate/${dataId}?field=upvoteUser`,
        {
          method: "GET",
          headers: { Authorization: token },
        }
      )
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.VideoUpload.upvoteUser);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  useEffect(() => {
    getContestDetails();
  }, []);
  return (
    <div className={styles.popupbox}>
      <div className={styles[formthemeStyle]} id={styles.box}>
        <span
          className={styles.closeicon}
          role="button"
          tabIndex={0}
          onClick={handleClose}
          onKeyPress={handleClose}
        >
          <svg
            height="329pt"
            viewBox="0 0 329.26933 329"
            width="329pt"
            xmlns="http://www.w3.org/2000/svg"
            id="fi_1828778"
          >
            <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
          </svg>
        </span>
        {loading ? (
          <SpinnerRoundFilled style={{ marginLeft: "50%" }} />
        ) : (
          <Container fluid className={styles.popupboxInner}>
            {!details || details.length === 0 ? (
              <p>No Users Upvoted yet</p>
            ) : (
              <Row>
                {details.map((user) => (
                  <Col key={user.email} lg={4} className={styles.userDetail}>
                    <div className={styles.userInfo}>
                      <Avatar
                        name={user.name}
                        src={user.image}
                        round
                        size="50"
                        color="#f56a00"
                        fgColor="#ffffff"
                        className={styles.userImage}
                      />
                      <div>
                        <p className={styles.userName}>{user.name}</p>
                        <p className={styles.userEmail}>{user.email}</p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        )}
      </div>
    </div>
  );
});

ViewUsersPopUp.propTypes = {
  dataId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default ViewUsersPopUp;
