/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */
import axios from "axios";
// import Avatar from 'react-avatar';
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { Col, Row, Container, Image } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import styles from "./viewVideo.module.scss";
import envValues from "../../enviornment";
import useToggle from "../../hooks/useToggle";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import VideoPopUp from "../../components/VideoViewPopUp/videoView";
import ViewUsersPopUp from "../../components/ViewUsersPopUp/ViewUsersPopup";
import { Entry } from "../../redux/entrySlice";

function ViewApprovedVideo() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const params = useParams();
  const [showVideoPopUp, setShowVideoPopUp] = useToggle();
  const [showUsersPopUp, setShowUsersPopUp] = useToggle();
  const [showButton, setShowButton] = useState(true);
  const { viewformStyle } = useContext(TableContext);
  let videoId;
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let statusCode;

  const [contest, setContest] = useState();

  const getCurrentContest = async () => {
    const actionData = {};
    actionData.actionUrl = `contest/get/current-contest`;
    actionData.actionMethod = "get";
    dispatch(Entry(actionData)).then((resp) => {
      setContest(resp.payload.data);
    });
  };

  useEffect(() => {
    getCurrentContest();
  }, []);

  const actionApprove = (data) => {
    if (!contest) {
      confirmAlert({
        title: "No Current Contest !!",
        message:
          "Do you still want to approve this video? Approved videos go-to queue. ",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `api/video/approve/${data.id}`;
              actionData.actionMethod = "patch";
              dispatch(Entry(actionData)).then(() => {
                toast.success("Approved Successfully");
                navigate(-1);
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ],
      });
    } else if (contest?.timeRemainingCurtain === 0) {
      confirmAlert({
        title: "Curtain Closed !!!",
        message: "Do you still want to approve this video ?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `api/video/approve/${data.id}`;
              actionData.actionMethod = "patch";
              dispatch(Entry(actionData)).then(() => {
                toast.success("Approved Successfully");
                navigate(-1);
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ],
      });
    } else {
      confirmAlert({
        title: "",
        message: "Are you sure you want to approve this video?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              const actionData = {};
              actionData.actionUrl = `api/video/approve/${data.id}`;
              actionData.actionMethod = "patch";
              dispatch(Entry(actionData)).then(() => {
                toast.success("Approved Successfully");
                navigate(-1);
              });
            },
          },
          {
            label: "No",
            // onClick: () => alert("Click No")
          },
        ],
      });
    }
  };

  const actionReject = (data) => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to reject this video?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const actionData = {};
            actionData.actionUrl = `api/video/reject/${data.id}`;
            actionData.actionMethod = "patch";
            dispatch(Entry(actionData)).then(() => {
              toast.success("Rejected Successfully");
              navigate(-1);
            });
          },
        },
        {
          label: "No",
          // onClick: () => alert("Click No")
        },
      ],
    });
  };

  const getContestDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/api/video/${videoId}`, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.VideoUpload);
          if (
            res.data.VideoUpload.status === "approved" ||
            res.data.VideoUpload.status === "rejected"
          ) {
            setShowButton(false);
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const handlePlay = () => {
    setShowVideoPopUp(true);
  };
  // const handlePopup = () => {
  //   setShowUsersPopUp(true);
  // };
  useEffect(() => {
    window.scrollTo(0, 0);
    videoId = params.videoId;
    getContestDetails();
    setDashboardHeader("Video Details");
  }, []);
  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          {loading ? (
            <SpinnerDotted
              style={{
                color: "#39979d",
                left: "50%",
                position: "relative",
                textAlign: "center",
                top: "50%",
              }}
            />
          ) : (
            <Container fluid>
              <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              />
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <div className={styles.thumbnailContainer}>
                      <Image
                        src={details.thumbnail}
                        alt={details.description}
                        className={styles.profilepic}
                      />
                      <FontAwesomeIcon
                        icon={faPlayCircle}
                        className={styles.playButton}
                        onClick={handlePlay}
                      />
                    </div>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>User Name</span>
                          <p className={styles.empname}>
                            {details.userName === undefined || ""
                              ? "N/A"
                              : details?.userName}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Title</span>
                          <p className={styles.empname}>
                            {details.description === undefined || ""
                              ? "N/A"
                              : details?.description}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Duration</span>
                          <p className={styles.empname}>
                            {details.duration === undefined || ""
                              ? "N/A"
                              : details?.duration}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Uploaded Date</span>
                          <p className={styles.empname}>
                            {details.uploadedTime
                              ? DateFormatter(
                                  details.uploadedTime,
                                  "toDateTime"
                                )
                              : "N/A"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Status</span>
                          <p className={styles.empname}>
                            {details.status === undefined || ""
                              ? "N/A"
                              : capitalizeFirstLetter(details.status)}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>
                            Is Opt For TV search
                          </span>
                          <p className={styles.empname}>
                            {details.isIncluded ? "Yes" : "No"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>View Count</span>
                          <p className={styles.empname}>
                            {details.viewCount === undefined || ""
                              ? "0"
                              : details?.viewCount}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                          style={{ cursor: "pointer" }}
                          onClick={() => handlePopup()}
                        >
                          <span className={styles.title}>Upvote Count</span>
                          <p className={styles.empname}>
                            {details.upvoteCount === undefined || ""
                              ? "0"
                              : details?.upvoteCount}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          style={{ cursor: "pointer" }}
                          id={styles.empdiv}
                          onClick={() => {
                            navigate(`/buy-interest/viewdetails/${details.id}`);
                          }}
                        >
                          <span className={styles.title}>Buy Interest</span>
                          <p className={styles.empname}>
                            {details.buyIntrestCount === undefined || ""
                              ? "0"
                              : details?.buyIntrestCount}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          className={styles[viewformStyle]}
                          style={{ cursor: "pointer" }}
                          id={styles.empdiv}
                          onClick={() => {
                            navigate(`/connect/viewdetails/${details.id}`);
                          }}
                        >
                          <span className={styles.title}>
                            Connected Users Count
                          </span>
                          <p className={styles.empname}>
                            {details.connectedUsersCount === undefined || ""
                              ? "0"
                              : details?.connectedUsersCount}
                          </p>
                        </div>
                      </Col>
                    </Row> */}
                    {showButton && (
                      <Row>
                        <Col lg={6}>
                          <input
                            className={styles.formbtn}
                            type="submit"
                            value="Approve"
                            onClick={() => actionApprove(details)}
                          />
                          <input
                            className={styles.resetbtn}
                            type="button"
                            value="Reject"
                            onClick={() => actionReject(details)}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
      {showVideoPopUp ? (
        <VideoPopUp dataId={details.id} handleClose={setShowVideoPopUp} />
      ) : null}
      {showUsersPopUp ? (
        <ViewUsersPopUp dataId={details.id} handleClose={setShowUsersPopUp} />
      ) : null}
    </div>
  );
}

export default ViewApprovedVideo;
