import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import TextInput from "../../components/TextField/TextField";
import { Entry } from "../../redux/entrySlice";
import { TableContext } from "../../contexts/tableContext";
import "react-bootstrap-typeahead/css/Typeahead.css";
import styles from "./createAppUpdate.module.scss";
import TagInput from "../../components/Typeahead/Typeahead";
import SelectBox from "../../components/SelectBox/SelectBox";
import CheckBoxField from "../../components/CheckBox/CheckBox";

function AppUpdateCreate() {
  const { dashboardStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const platform = [
    { label: "Android", value: "android" },
    { label: "iOS", value: "iOS" },
  ];
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader("Add App Upgrade details");
  }, []);
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  function onSubmitdata(data) {
    setSubmitting(true);
    setError(null);
    const apiData = {
      description: data.description,
      features: data.features.map((tag) => tag.label),
      platform: data.platform.value,
      version: data.version,
      forceUpdate: data.forceUpdate,
    };
    data.actionUrl = "appupdate";
    data.actionMethod = "post";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success("App Updation Data created successfully");
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
      } else {
        setError("Something went wrong!");
      }
    });
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Description"
                    fieldName="description"
                    placeHolder="Enter description"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TagInput
                    // className={styles.inputbox}
                    // labelstyle={styles.formlabel}
                    label="Features"
                    placeHolder="Enter Features"
                    fieldName="features"
                    register={register}
                    errors={errors}
                    control={control}
                    isRequired
                    mandatory
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Version"
                    fieldName="version"
                    placeHolder="Enter version"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="platform"
                    label="Platform"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={platform}
                    errors={errors}
                    placeholder="Choose Platform"
                    mandatory
                  />
                </div>
              </div>
              <div className="row">
                <CheckBoxField
                  fieldName="forceUpdate"
                  label="Do you want to force update?"
                  register={register}
                  errors={errors}
                  labelstyle={styles.formlabel}
                />
              </div>
              <div className="d-flex">
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? "Please wait.." : "Submit"}
                />
              </div>
              <br />
              {error && (
                <h6
                  className={styles[errormsgStyle]}
                  style={{ float: "right" }}
                >
                  {error}
                </h6>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppUpdateCreate;
