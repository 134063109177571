import envValues from "../enviornment";

export const SERVER_URL = `${envValues.REACT_APP_API_ENDPOINT}/auth`;
export const SERVER_BASE_URL = envValues.REACT_APP_API_ENDPOINT;
export const ERROR_MESSAGE = "Api Fetch Error!";
export const ENCRYPT_SECERET_KEY = "armia-secret-key@123";
export const REACT_APP_API_POST_USER = `${envValues.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${envValues.REACT_APP_API_ENDPOINT}/user?`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${envValues.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${envValues.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_SKILLS = `${envValues.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/roles/select`;
export const REACT_APP_API_FETCH_MOVIES = `${envValues.REACT_APP_API_ENDPOINT}/movies?active=true&`;
export const REACT_APP_API_FETCH_NOTIFICATIONS = `${envValues.REACT_APP_API_ENDPOINT}/notifications?`;
export const REACT_APP_API_FETCH_WAITLIST = `${envValues.REACT_APP_API_ENDPOINT}/waitlist?`;
export const REACT_APP_API_FETCH_HISTORICAL = `${envValues.REACT_APP_API_ENDPOINT}/historical?`;
export const REACT_APP_API_FETCH_STOKES = `${envValues.REACT_APP_API_ENDPOINT}/stock?`;
export const REACT_APP_API_FETCH_REFERRALS = `${envValues.REACT_APP_API_ENDPOINT}/referal?`;
export const REACT_APP_API_FETCH_CONTEST = `${envValues.REACT_APP_API_ENDPOINT}/contest?status=created&`;
export const REACT_APP_API_FETCH_PAST_CONTEST = `${envValues.REACT_APP_API_ENDPOINT}/contest/past-contest?`;
export const REACT_APP_API_FETCH_CURRENT_CONTEST = `${envValues.REACT_APP_API_ENDPOINT}/contest/current-contest?`;
export const REACT_APP_API_FETCH_VIDEO = `${envValues.REACT_APP_API_ENDPOINT}/api/video/admin?`;
export const REACT_APP_API_FETCH_APPROVED_VIDEOS = `${envValues.REACT_APP_API_ENDPOINT}/api/video/approved?`;
export const REACT_APP_API_FETCH_BUY_INTEREST = `${envValues.REACT_APP_API_ENDPOINT}/api/video/buy-interest?`;
export const REACT_APP_API_FETCH_CONNECT = `${envValues.REACT_APP_API_ENDPOINT}/api/video/connect-list?`;
export const REACT_APP_API_FETCH_FINALIST = `${envValues.REACT_APP_API_ENDPOINT}/api/video/finalist?`;
export const REACT_APP_API_FETCH_REPORTED = `${envValues.REACT_APP_API_ENDPOINT}/reported?`;
export const REACT_APP_API_FETCH_REPORTED_CONCLUDED = `${envValues.REACT_APP_API_ENDPOINT}/reported/concluded?`;
export const REACT_APP_API_FETCH_CMS = `${envValues.REACT_APP_API_ENDPOINT}/cms?`;
export const REACT_APP_API_FETCH_CMS_APPROVED = `${envValues.REACT_APP_API_ENDPOINT}/cms/audit?`;
export const REACT_APP_API_FETCH_APP_UPDATES = `${envValues.REACT_APP_API_ENDPOINT}/appupdate?`;
export const REACT_APP_API_FETCH_REJECTED_VIDEOS = `${envValues.REACT_APP_API_ENDPOINT}/api/video/rejected?`;
