/* eslint-disable react/function-component-definition */
import React, { useEffect, useContext } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useParams } from "react-router-dom";
import styles from "./index.module.scss";
import CustomTable from "../../components/CustomTable/CustomTable";
import { TableContext } from "../../contexts/tableContext";
import envValues from "../../enviornment";

const ViewConnectList = React.memo(() => {
  const params = useParams();
  const { setDashboardHeader } = useContext(TableContext);
  setDashboardHeader("Connect List");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.indexdiv}>
      <CustomTable
        urlParam={`${envValues.REACT_APP_API_ENDPOINT}/api/video/connect-list/${params.videoId}?`}
        // viewPopUp
        section="connect"
        // toggleButton={{ show: true, field: "active" }}
        deleteCondition={{ checkCondition: false, apiUrl: "www.google.com" }}
      />
    </div>
  );
});
export default ViewConnectList;
