/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import styles from "./ExportToExcel.module.scss";
import { SERVER_BASE_URL } from "../../redux/apiConstants";
import { getCookies } from "../../hooks/useCookies";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";

function ExportToExcel({ apiUrl, fileName, section, disabled }) {
  const token = `Bearer ${getCookies("Token")}`;
  const exportToCSV = (appendUrl, filename) => () => {
    const url = `${SERVER_BASE_URL}/${appendUrl}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (section === "buy-interest") {
          if (resp.data.VideoUpload.buyIntrestUser.length) {
            // eslint-disable-next-line no-underscore-dangle
            const cleanArray = resp.data.VideoUpload.buyIntrestUser.map(
              (item) => {
                const { name, email, phone } = item;
                return { Name: name, Email: email, Phone: phone };
              }
            );
            const ws = XLSX.utils.json_to_sheet(cleanArray);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            XLSX.writeFile(wb, `${filename}sheetjs.xlsx`);
          }
        } else if (section === "connect") {
          if (resp.data.VideoUpload.connectedUsers.length) {
            // eslint-disable-next-line no-underscore-dangle
            const cleanArray = resp.data.VideoUpload.connectedUsers.map(
              (item) => {
                const { name, email, phone } = item;
                return { Name: name, Email: email, Phone: phone };
              }
            );
            const ws = XLSX.utils.json_to_sheet(cleanArray);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            XLSX.writeFile(wb, `${filename}sheetjs.xlsx`);
          }
        } else if (section === "audit") {
          if (resp.data.result.results.length) {
            // eslint-disable-next-line no-underscore-dangle
            const cleanArray = resp.data.result.results.map((item) => {
              const { userName, email } = item;
              const privacyPolicyPublishTime = item.privacyPolicyPublishTime
                ? DateFormatter(item.privacyPolicyPublishTime, "toDate")
                : "N/A";
              const privacyPolicyApprovedTime = item.privacyPolicyApprovedTime
                ? DateFormatter(item.privacyPolicyApprovedTime, "toDate")
                : "N/A";
              const termsAndConditionsPublishTime =
                item.termsAndConditionsPublishTime
                  ? DateFormatter(item.termsAndConditionsPublishTime, "toDate")
                  : "N/A";
              const termsAndConditionsApprovedTime =
                item.termsAndConditionsApprovedTime
                  ? DateFormatter(item.termsAndConditionsApprovedTime, "toDate")
                  : "N/A";
              return {
                Name: userName,
                Email: email,
                Privacy_Policy_Publish_Time: privacyPolicyPublishTime,
                Privacy_Policy_Approved_Time: privacyPolicyApprovedTime,
                Terms_Publish_Time: termsAndConditionsPublishTime,
                Terms_Approved_Time: termsAndConditionsApprovedTime,
              };
            });
            const ws = XLSX.utils.json_to_sheet(cleanArray);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            XLSX.writeFile(wb, `${filename}sheetjs.xlsx`);
          }
        }
      }); // setting response to state posts
  };
  return (
    <button
      type="button"
      className={styles.exportbtn}
      onClick={exportToCSV(apiUrl, fileName)}
      disabled={disabled}
    >
      Export Excel
    </button>
  );
}

ExportToExcel.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  section: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

ExportToExcel.defaultProps = {
  section: "User",
};

export default ExportToExcel;
