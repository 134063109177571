/* eslint-disable react/button-has-type */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateRangePicker } from "react-date-range";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"; // Importing trash icon
import styles from "./DateRangeFilter.module.scss";
import { TableContext } from "../../contexts/tableContext";

function DateRangeFilter({ onChange, open, setOpen }) {
  const { bodyheader, searchboxStyle } = useContext(TableContext);
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
  };

  const handleClear = () => {
    const clearedRange = {
      startDate: null,
      endDate: null,
      key: "selection",
    };
    setState([clearedRange]);
    onChange(clearedRange); // Notify the parent component
  };

  const formatDate = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");
  const endDate = formatDate(state[0].endDate);
  const startDate = formatDate(state[0].startDate);

  return (
    <div className="mb-3">
      <p className={styles[bodyheader]} id={styles.chooseheading}>
        Choose a date range
      </p>
      <div className={styles.dateContainer}>
        {state[0].startDate && state[0].endDate && (
          <>
            <span className={styles[searchboxStyle]} id={styles.dateview}>
              {startDate} {"- "}
              {endDate}
            </span>
            <button onClick={handleClear} className={styles.clearbtn}>
              <FontAwesomeIcon icon={faTrashAlt} className={styles.clearIcon} />
            </button>
          </>
        )}
        <button onClick={() => setOpen(!open)} className={styles.openbtn}>
          <FontAwesomeIcon
            icon={faCalendarDays}
            className={styles.calendaricon}
          />
        </button>
      </div>
      {open && (
        <DateRangePicker
          className={styles.datepicker}
          onChange={handleOnChange}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={state}
          direction="horizontal"
          dateDisplayFormat="yyyy-MM-dd"
          staticRanges={[]}
          inputRanges={[]}
        />
      )}
    </div>
  );
}

DateRangeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
};
DateRangeFilter.defaultProps = {
  open: false,
};

export default DateRangeFilter;
