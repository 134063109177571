/* eslint-disable react/function-component-definition */
import React, { useEffect, useContext } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useParams } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import styles from "./index.module.scss";
import CustomTable from "../../components/CustomTable/CustomTable";
import { TableContext } from "../../contexts/tableContext";
import envValues from "../../enviornment";

const ViewBuyInterest = React.memo(() => {
  const params = useParams();
  // const navigate = useNavigate();
  const { setDashboardHeader } = useContext(TableContext);
  setDashboardHeader("Video Buy Request List");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.indexdiv}>
      {/* <button
        type="button"
        style={{
          position: "absolute",
          bottom: "10%",
          left: "20%",
          padding: "10px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "5px",
          fontSize: "18px", // Larger font size for the icon
          cursor: "pointer",
          zIndex: 1000,
        }}
        onClick={() => navigate(-1)} // Go back to the previous page
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button> */}

      <CustomTable
        urlParam={`${envValues.REACT_APP_API_ENDPOINT}/api/video/buy-interest/${params.videoId}?`}
        // viewPopUp
        section="buy-interest"
        // toggleButton={{ show: true, field: "active" }}
        deleteCondition={{ checkCondition: false, apiUrl: "www.google.com" }}
      />
    </div>
  );
});
export default ViewBuyInterest;
